// Import necessary modules
import React, { useEffect } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';

import { EDITOR_JS_TOOLS } from './tools';

interface EditorProps {
  data: OutputData; // Optional initial data in JSON format
  onUpdate?: (data:OutputData) => void
}


const EDITOR_HOLDER_ID = 'editorjs';
// Define the Editor component
const Editor: React.FC<EditorProps> = ({data, onUpdate}) => {
  
  let editor:any = { isReady: false };

  console.log('Editor data', data);

  // Initialize the editor when the component mounts
  useEffect(() => {
    if (!editor.isReady) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      editor = new EditorJS({
        holder: EDITOR_HOLDER_ID,
        data: data,
        onChange: async (api) => {
          const content = await api.saver.save();
          if (onUpdate) {onUpdate(content)}
        },
        autofocus: true,
        tools: EDITOR_JS_TOOLS,
      });
    }
    }, []);

  useEffect(() => {
    if (editor.isReady) {
      console.log('новый данные', data);
      // editor.
    }
  }, [data])

  return (
    <React.Fragment>
      <div id={EDITOR_HOLDER_ID}> </div>
    </React.Fragment>
  );
};

export default Editor;
