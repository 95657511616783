import {
    applySnapshot,
    types as t,
} from 'mobx-state-tree';

export const initialDomainStore = {
    domains: [],
    selectedDomain: null,
    loaded: false,
};

export const DomainStore = t
    .model({
        domains: t.array(
            t.model({
                id: t.string,
                name: t.string,
                isAdmin: t.boolean,
            })
        ),
        selectedDomain: t.maybeNull(t.string),
        loaded: t.boolean,
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialDomainStore);
        },
        setDomains: (data: any | null) => {
            self.domains = data;
            if (self.domains.length > 0) {
                self.selectedDomain = self.domains[0].id
            }
            self.loaded = true
        },
        pushDomain: (domain: { id: string, name: string, isAdmin: boolean }) => {
            self.domains.push(domain);
            self.selectedDomain = domain.id
        },
        selectDomain: (id: string) => {
            self.selectedDomain = id
        }
    }));
