import { useTranslation } from 'react-i18next';
import { Select } from 'antd'
import _ from 'lodash';

import {status} from '../../../shared/status';

export const SelectStatus = (props:any) => {

  const {t} = useTranslation()

  const options = _.map(status, item => ({
    value: item.name,
    label: t(item.name),
  }))

  // options.push({
  //     value: null,
  //     label: <span>{t('No epic')}</span>,
  // })

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...props} options={options} placeholder={t('No Status')}/>
  )
}
