import React, { FC } from 'react';
import { provider, useInstance } from 'react-ioc';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { ConfigProvider, theme } from 'antd';
import { observer } from 'mobx-react-lite';

import ScrollToTop from './components/common/ScrollToTop';
import GetMe from './features/GetMe/GetMe';
import { Layout } from './layouts/Layout';
import ApolloClientDI from './model/graphql/ApolloClientDI';
import { Store } from './model/store/Store';
import StoreDI from './model/store/StoreDI';
import LoginPage from './pages/Auth/LoginPage';
import LogoutPage from './pages/Auth/LogoutPage';
import RegisterPage from './pages/Auth/RegisterPage';
import CreateDomainPage from './pages/Domain/CreateDomainPage';
import CreateInvitePage from './pages/Domain/CreateInvitePage';
import { Archive } from './pages/Index/Archive';
import { Index } from './pages/Index/Index';
import NotFound from './pages/NotFound';
import { Task } from './pages/Task/Task';
import {
  ARCHIVE,
  DOMAIN_CREATE,
  DOMAIN_INVITE,
  INDEX,
  LOGIN,
  LOGOUT,
  NOT_FOUND,
  REGISTER, ROOT_APP, ROOT_DOMAIN, ROOT_USER, TASK
} from './routes';

import './App.scss';

const App: FC = () => {
  const store = useInstance(Store);
  const { hydrated, auth } = store;
  const { hasAuthToken } = auth;
  const apolloClient = useInstance(ApolloClient);

  if (!hydrated) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7037CD',
            fontFamily: 'SF Pro, sans-serif',
            fontSize: 15
            // controlHeight: 40,
          },
          algorithm: theme.darkAlgorithm
        }}>
        <Router>
          <ScrollToTop />

          {!hasAuthToken && (
            <Routes>
              <Route path={REGISTER} element={<RegisterPage />} />
              <Route path={LOGIN} element={<LoginPage />} />
              <Route path="*" element={<Navigate to={LOGIN} />} />
            </Routes>
          )}
            
          {hasAuthToken &&
            <>
              <GetMe />
              {store.domain.loaded && store.domain.selectedDomain === null ?
                <Routes>
                  <Route path={DOMAIN_CREATE} element={<CreateDomainPage />} />
                  <Route path="*" element={<Navigate to={DOMAIN_CREATE} />} />
                  <Route path={NOT_FOUND} element={<NotFound />} />
                </Routes>
                :
                <Routes>

                  <Route path={INDEX} element={<Index />} />
                  <Route path={ARCHIVE} element={<Archive />} />

                  <Route path={ROOT_APP} element={<Layout />}>
                    <Route path={TASK} element={<Task />} />
                  </Route>

                  <Route path={ROOT_USER} element={<Layout />}>
                    <Route path={LOGOUT} element={<LogoutPage />} />
                  </Route>

                  <Route path={ROOT_DOMAIN} element={<Layout />}>
                    <Route path={DOMAIN_CREATE} element={<CreateDomainPage />} />
                    <Route path={DOMAIN_INVITE} element={<CreateInvitePage />} />
                  </Route>

                  <Route path="*" element={<Navigate to={INDEX} />} />
                  <Route path={NOT_FOUND} element={<NotFound />} />

                </Routes>
              }
            </>
          }

        </Router>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default provider(
  StoreDI(),
  ApolloClientDI()
)(observer(App));
