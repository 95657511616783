import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Link, useNavigate } from 'react-router-dom';
import { CheckOutlined, DatabaseOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Avatar, Button,Dropdown } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';
import { ARCHIVE, DOMAIN_CREATE, INDEX, LOGOUT } from 'routes';

import img from './Saturn_Logo.svg'

import cls from './Header.module.scss'

interface IHeader {
  filters?: ReactNode
}

export const Header = observer(({filters}: IHeader) => {

  const store = useInstance(Store);
  const {t} = useTranslation()
  const navigate = useNavigate()

  const menuAvatar: MenuProps['items'] = [
    {
      key: 'logout',
      label: (<Link to={LOGOUT}>{t('Logout')}</Link>),
    },
  ];

  const menuDomains: MenuProps['items'] = _.map(store.domain.domains, domain => ({
    key: domain.id,
    label: domain.name,
    onClick: () => store.domain.selectDomain(domain.id),
    icon: store.domain.selectedDomain === domain.id && <CheckOutlined/>

  }))

  menuDomains.push({
    key: 'new',
    label: t('Add'),
    onClick: () => navigate(DOMAIN_CREATE),
    icon: <PlusOutlined/>
  })

  menuDomains.push({
    key: 'archive',
    label: t('Archive'),
    onClick: () => navigate(ARCHIVE),
    icon: <DatabaseOutlined/>
  })

  return (
    <header className={cls.header}>
      <div className={cls.left}>
        <Dropdown menu={{items: menuAvatar}} trigger={['click']}>
          <Avatar size={32} style={{backgroundColor: store.auth.data?.color!}}>{store.auth.data?.username!.charAt(0)}</Avatar>
        </Dropdown>

        <Dropdown menu={{selectedKeys: [store.domain.selectedDomain!], items: menuDomains}} trigger={['click']}>
          <Button icon={<MenuOutlined/>} type={'text'}/>
        </Dropdown>

        <Link to={INDEX}><img src={img} alt={'Saturn Logo'}/></Link>
      </div>
      <div className={cls.right}>
        {filters}
      </div>
    </header>
  )
})