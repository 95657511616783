import { FC, useEffect } from 'react';
import {useInstance} from 'react-ioc';
import {useGetMeQuery} from 'generated/graphql';
import {observer} from 'mobx-react-lite';
import {Store} from 'model/store/Store';

const GetMe: FC = () => {

    const store = useInstance(Store);

    const me = useGetMeQuery({
        fetchPolicy: 'no-cache',
        pollInterval: 1000,
        // onCompleted: (me) => {
        //     const role = me.me?.role?.name!
        //     const data = {
        //         username: me.me?.username!,
        //         color: me.me?.color!,
        //         focusedAt: me.me?.focusedAt
        //     }
        //     store.auth.setRoleAndData(role, data);
        //     store.domain.setDomains(me.me?.domains)
        // }
    });

    useEffect(() => {
        if (me.data) {
            const role = me.data?.me?.role?.name!
            const data = {
                username: me.data?.me?.username!,
                color: me.data?.me?.color!,
                focusedAt: me.data?.me?.focusedAt
            }
            store.auth.setRoleAndData(role, data);
            store.domain.setDomains(me.data?.me?.domains)
        }
    }, [me, store])

    return null
};

export default observer(GetMe);



