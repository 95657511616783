import { useState } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import _, { toNumber } from 'lodash';

import { useReportsQuery, useTimersQuery } from '../../generated/graphql';
import { MinutesFormat } from '../../shared/MinutesFormat';

import { BoardData } from './Board';
import { Tag } from './Tag';

import cls from './Card.module.scss';
dayjs.extend(duration)

interface ICard {
  data: BoardData;
  onCardOpen?: () => void;
  onCardDrag?: () => void;
  onCardDrop?: () => void;
  onCardPreview?: () => void;
}

export const Card = ({ data, onCardOpen, onCardDrag }: ICard) => {

  // запрос репортов на бэк
  const reports = useReportsQuery({
    variables: {
      filters: {
        task: { id: { eq: data.id } }
      },
      pagination: {
        limit: 1000,
      },

    },
    // pollInterval: 2000,
  });

  const totalMinutes: number = _.reduce(reports.data?.reports?.data, function(sum, item) {
    return sum + toNumber(item.attributes?.minutes!);
  }, 0);

  // запрос таймеров на бэк
  const timers = useTimersQuery({
    variables: {
      filters: {
        task: { id: { eq: data.id } }
      },
      pagination: {
        limit: 1000,
      },
    },
    // pollInterval: 2000,
  });

  const totalSeconds: number = _.reduce(timers.data?.timers?.data, function(sum, item) {
    if (item.attributes?.finishedAt !== null) {
      return sum + dayjs(item.attributes?.finishedAt).diff(dayjs(item.attributes?.createdAt), 'seconds' )*1000
    } else {
      return sum
    }
  }, 0);


  const onMouseDown = () => {
    console.log('pressed');
  };

  const [isDragging, setIsDragging] = useState<boolean>(false);

  function handleDragStart(e: any) {
    setIsDragging(true);
    const data = JSON.stringify({ type: 'card' });
    e.dataTransfer.setData('text/plain', data);
    // if (onCardDrag) {onCardDrag()}
    // console.log('start', e);
  }

  function handleDragEnd(e: any) {
    setIsDragging(false);
    e.dataTransfer.clearData();
    // if (onCardDrop) {onCardDrop()}
    // console.log('end', e);
  }

  return (
    <div className={cn([cls.card, isDragging && cls.dragging])}
         onClick={onCardOpen}
         onDrag={onCardDrag}
         onDragStart={handleDragStart}
         onMouseDown={onMouseDown}
         onDragEnd={handleDragEnd}
         draggable
    >
      <div className={cls.title}>{data.name}</div>
      <div className={cls.tags}>
        {data.client && <Tag data={data.client} />}
        {data.epic && <Tag data={data.epic} />}
        {_.map(data.users, (user, index) => (<Tag key={index} data={user} />))}
        {totalMinutes > 0 && <Tag data={{ id: 'time', name: `✅ ${MinutesFormat(totalMinutes)}`, color: '#333333' }} />}
        {totalSeconds > 0 && <Tag data={{ id: 'time', name: `🕑 ${dayjs.duration(totalSeconds).format('HH:mm')}`, color: '#333333' }} />}
      </div>
    </div>
  );
};